import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { AxiosAPI, getToken } from '@bae/api';

import { FinancialStatementData } from '../types';
import { APIFinancialStatementData } from './types';
import { FinancialEndpoint } from './types.ts';

const transformFinancialsData = (
    d: APIFinancialStatementData,
): FinancialStatementData => ({
    exportUrl: d.exporturl,
    header: d.header,
    body: d.body,
});

const getFinancialsData = async (
    modelId: string,
    endpoint: FinancialEndpoint,
): Promise<FinancialStatementData> => {
    const url = `financials/${modelId}/${endpoint}`;

    const response = await AxiosAPI.axiosInstance.get(url, {
        headers: { Authorization: `Bearer ${getToken()}` },
    });
    return transformFinancialsData(response.data);
};

export const getFinancialsQueryOptions = (
    modelId: string,
    endpoint: FinancialEndpoint,
) => {
    return queryOptions({
        queryKey: ['financials', modelId, endpoint],
        queryFn: () => getFinancialsData(modelId, endpoint),
    });
};

type UseFinancialsOptions = {
    queryConfig?: QueryConfig<typeof getFinancialsData>;
    refetchInterval?: number | false;
};

// Custom hook
export const useFinancialsApi = (
    modelId: string,
    endpoint: FinancialEndpoint,
    { queryConfig, refetchInterval }: UseFinancialsOptions = {},
) => {
    return useQuery({
        ...getFinancialsQueryOptions(modelId, endpoint),
        ...queryConfig,
        refetchInterval,
    });
};

// Endpoint-specific hooks for convenience
export const useFinancialCashFlows = (
    modelId: string,
    period: 'yearly' | 'monthly',
    options?: UseFinancialsOptions,
) => {
    const endpoint: FinancialEndpoint =
        period === 'monthly' ? 'monthlycashflows' : 'yearlycashflows';

    return useFinancialsApi(modelId, endpoint, options);
};

export const useFinancialOperatingIncome = (
    modelId: string,
    period: 'yearly' | 'monthly',
    options?: UseFinancialsOptions,
) => {
    const endpoint: FinancialEndpoint =
        period === 'monthly' ? 'monthlyopincome' : 'yearlyopincome';

    return useFinancialsApi(modelId, endpoint, options);
};

export const useAveragesEndPoint = (
    modelId: string,
    options?: UseFinancialsOptions,
) => useFinancialsApi(modelId, 'endpointavg', options);

export const useAveragesTenant = (
    modelId: string,
    options?: UseFinancialsOptions,
) => useFinancialsApi(modelId, 'tenantavg', options);

export const useAveragesDataRate = (
    modelId: string,
    options?: UseFinancialsOptions,
) => useFinancialsApi(modelId, 'datarateavg', options);

export const useAveragesDataVolume = (
    modelId: string,
    options?: UseFinancialsOptions,
) => useFinancialsApi(modelId, 'datavolumeavg', options);
