import { useCurrency } from '@bae/store';
import { formatCurrencySymbol } from '@bae/utils';

import { Skeleton } from '@/components/ui/skeleton.tsx';

import { TableBodyRow, TableHeadRow } from '../../shared/TableComponents';
import {
    TextInputCell,
    FloatNumberInputCell,
    CurrencyInputCell,
} from '../../shared/TableComponents/CellComponents';
import { FTE } from '../../shared/types.ts';
import { getMetalFtes, useCloudRebalancingStore } from '../store';

const getFteLabels = (currencySign: string = '$') => ({
    fteName: { label: 'FTE Name', isEditable: true },
    annualExpense: {
        label: `Annual Expense (${currencySign})`,
        isEditable: true,
    },
    annualGrowth: { label: 'Annual Expense Growth (%)', isEditable: true },
    quantity: { label: 'Number of FTEs', isEditable: true },
});

const MetalFTEs = () => {
    const { currency } = useCurrency();

    const metalFtes = useCloudRebalancingStore(getMetalFtes);
    const updateMetalFte = useCloudRebalancingStore(
        (state) => state.updateMetalFte,
    );
    const loading = metalFtes.length === 0;

    const currencySign = formatCurrencySymbol({
        currency,
        onlySymbol: true,
    });
    const fteLabels = getFteLabels(currencySign);

    return (
        <div className='w-full overflow-x-auto'>
            <table className='w-full table-fixed border-separate border-spacing-2 text-sm'>
                <thead>
                    {loading ? (
                        <SkeletonHeader />
                    ) : (
                        <TableHeadRow>
                            {Object.keys(fteLabels).map((key) => (
                                <th key={key} className='text-center'>
                                    <p className='whitespace-nowrap'>
                                        {fteLabels[key as keyof FTE].label}
                                    </p>
                                </th>
                            ))}
                        </TableHeadRow>
                    )}
                </thead>
                <tbody>
                    {loading ? (
                        <SkeletonRow />
                    ) : (
                        metalFtes.map((fte, index) => (
                            <TableBodyRow key={index}>
                                <TextInputCell<FTE>
                                    value={fte.fteName}
                                    onChange={updateMetalFte}
                                    accessorKey={'fteName'}
                                    index={index}
                                    isEditable={true}
                                    className='min-w-[200px]'
                                />
                                <CurrencyInputCell<FTE>
                                    value={fte.annualExpense}
                                    accessorKey={'annualExpense'}
                                    index={index}
                                    onChange={updateMetalFte}
                                />
                                <FloatNumberInputCell<FTE>
                                    value={fte.annualGrowth}
                                    accessorKey={'annualGrowth'}
                                    index={index}
                                    onChange={updateMetalFte}
                                />
                                <FloatNumberInputCell<FTE>
                                    value={fte.quantity}
                                    accessorKey={'quantity'}
                                    index={index}
                                    onChange={updateMetalFte}
                                />
                            </TableBodyRow>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

const SkeletonHeader = () => {
    return (
        <TableHeadRow>
            <th>FTE Name</th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
        </TableHeadRow>
    );
};

const SkeletonRow = () => {
    const arr = Array.from({ length: 4 }, (_, i) => i);
    return (
        <TableBodyRow>
            <td>
                <Skeleton />
            </td>
            {arr.map((col) => (
                <td key={col}>
                    <Skeleton />
                </td>
            ))}
        </TableBodyRow>
    );
};

export default MetalFTEs;
