import { ComponentProps } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { useCurrency } from '@bae/store';

import { cn } from '@/lib/utils.ts';

const CurrencyInputCell = <T,>({
    value,
    onChange,
    accessorKey,
    index,
    className,
}: {
    value: number;
    onChange: <K extends keyof T>(index: number, key: K, value: T[K]) => void;
    accessorKey: keyof T;
    index: number;
    className?: ComponentProps<'div'>['className'];
}) => {
    const { currency } = useCurrency();

    const handleInputChange = (value: string) => {
        if (value === '') {
            onChange(index, accessorKey, 0 as T[keyof T]);
            return;
        }

        onChange(index, accessorKey, value as T[keyof T]);
    };

    return (
        <td className='h-[36px] rounded-md border bg-white p-2 text-center'>
            <CurrencyInput
                value={value}
                intlConfig={{ locale: 'en-US', currency }}
                className={cn(
                    'h-6 w-full rounded-sm border-none text-center focus-visible:outline-none',
                    'focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2',
                    className,
                )}
                decimalsLimit={8}
                defaultValue={0}
                onValueChange={(value) => {
                    handleInputChange(value || '0');
                }}
            />
        </td>
    );
};

export default CurrencyInputCell;
