import React from 'react';
import { LuLibrary } from 'react-icons/lu';
import { useSelector } from 'react-redux';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';
import { Button } from '@/components/ui/button.tsx';
import { cn } from '@/lib/utils.ts';
import { getEndpointType } from '@/libs/modules/model-designer/src/lib/store/selectors';

import { useLibraryPanelStore } from './store.ts';

interface Props {
    variant: 'extended' | 'icon';
}

const LibraryPanel = ({ variant }: Props) => {
    const isOpen = useLibraryPanelStore((state) => state.isOpen);
    const openPanel = useLibraryPanelStore((state) => state.openPanel);
    const closePanel = useLibraryPanelStore((state) => state.closePanel);
    const type = useSelector(getEndpointType);

    const triggerButtonWithText = variant === 'extended';

    if (type !== 'model') {
        return null;
    }

    return (
        <TooltipWrapper text='Library Panel'>
            <Button
                id='library-toggle-button'
                variant={triggerButtonWithText ? 'outline' : 'text'}
                size={triggerButtonWithText ? 'sm' : 'icon'}
                onClick={() => {
                    isOpen ? closePanel() : openPanel();
                }}
                className={cn({
                    'border border-newDesign-primary': !triggerButtonWithText,
                })}>
                <LuLibrary className='size-6' />
                {triggerButtonWithText && 'Library Panel'}
            </Button>
        </TooltipWrapper>
    );
};

export default LibraryPanel;
