import { RouteMap } from '@bae/routes';

import { getEnabledAppFeatures } from '@/app-config/featureConfig.ts';

/**
 * Determines if a route is allowed based on enabled routes and shared tabs.
 *
 * @param pathName - The route path to check.
 * @param sharedTabs - Array of shared route paths.
 * @param isShared - True if the route is a shared tab; false otherwise.
 * @returns True if the route is allowed; false otherwise.
 *
 * The function checks:
 * - If `pathName` is included in the list of enabled scenario routes.
 * - For shared routes (`isShared` is true), it also ensures `pathName` is in `sharedTabs`.
 *
 * **Why This Matters:**
 * Centralizing route permission logic helps maintain consistency across the application.
 * By using this function, we ensure that users only have access to the routes intended for their app specialization and enabled features,
 * enhancing both security and user experience.
 */
export const getIsAllowedRoute = (
    pathName: string,
    sharedTabs: string[],
    isShared: boolean,
) => {
    const sharedTabsWithMicrodata = sharedTabs.map((tab) => {
        if (tab === 'microdata') {
            return RouteMap.microdata.path;
        }
        return tab;
    });

    const enabledScenarioRoutes =
        getEnabledAppFeatures().enabledScenarioRoutes.map(
            (route) => route.path,
        );

    return (
        enabledScenarioRoutes.includes(pathName) &&
        (!isShared || sharedTabsWithMicrodata?.includes(pathName))
    );
};
