import React from 'react';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbList,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb.tsx';

export const ModelNameTag = ({ modelName }: { modelName: string }) => {
    const modelNameParts = modelName?.split('/') || [];

    return (
        <Breadcrumb>
            <BreadcrumbList>
                {modelNameParts.map((part, i) => {
                    if (i === modelNameParts.length - 1) {
                        return (
                            <BreadcrumbItem
                                className='text-sm font-semibold text-newDesign-secondary'
                                key={i}>
                                <p className='max-w-fit break-all'>{part}</p>
                            </BreadcrumbItem>
                        );
                    }

                    return (
                        <React.Fragment key={i}>
                            <BreadcrumbItem className='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-semibold'>
                                <p className='max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap'>
                                    {part}
                                </p>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                        </React.Fragment>
                    );
                })}
            </BreadcrumbList>
        </Breadcrumb>
    );
};
